import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Media from 'react-bootstrap/Media';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Image from 'react-bootstrap/Image';
import JobTagList from './JobTagList';

const CompanyLocation = styled.div`
  color: gray;
  font-size: 80%;
`;

const StyledTable = styled(Table)`
  td {
    vertical-align: middle;
  }
  th {
    border-top: none;
  }
`;

const CompanyColumn = styled.div`
  display: flex;
  align-items: center;
`;

const JobTitleLink = styled(Link)`
  color: #000;
  font-weight: bold;
`;

const JobList = ({ jobs }) => {
  return (
    <>
      <div className="table-responsive d-none d-sm-block">
        <StyledTable hover>
          <thead>
            <tr>
              <th>会社名</th>
              <th>職種</th>
              <th>勤務地</th>
              <th>キーワード</th>
            </tr>
          </thead>
          <tbody>
            {jobs.map(({ node }) => {
              return (
                <tr key={node.frontmatter.jobId}>
                  <td style={{ width: '250px' }}>
                    <CompanyColumn>
                      <Image
                        className="mr-2"
                        roundedCircle
                        src={
                          node.frontmatter.company.frontmatter.logo
                            ?.childImageSharp.fluid.src
                        }
                        alt="logo"
                      />
                      <Link to={node.frontmatter.company.fields.slug}>
                        {node.frontmatter.company.frontmatter.name}
                      </Link>
                    </CompanyColumn>
                  </td>
                  <td>
                    <JobTitleLink to={node.fields.slug}>
                      {node.frontmatter.title}
                    </JobTitleLink>
                  </td>
                  <td>{node.frontmatter.company.frontmatter.location}</td>
                  <td>
                    <JobTagList tags={node.frontmatter.tags} />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </StyledTable>
      </div>
      <div className="d-block d-sm-none">
        {jobs.map(({ node }) => {
          return (
            <Media className="mb-3" key={node.fields.slug}>
              <Image
                className="mr-2"
                roundedCircle
                src={
                  node.frontmatter.company.frontmatter.logo?.childImageSharp
                    .fluid.src
                }
                alt="logo"
              />
              <Media.Body>
                <Row>
                  <Col xs={12} lg={7}>
                    <Link to={node.frontmatter.company.fields.slug}>
                      {node.frontmatter.company.frontmatter.name}
                    </Link>
                    <CompanyLocation>
                      {node.frontmatter.company.frontmatter.location}
                    </CompanyLocation>
                    <Link to={node.fields.slug}>{node.frontmatter.title}</Link>
                  </Col>
                  <Col>
                    <JobTagList tags={node.frontmatter.tags} />
                  </Col>
                </Row>
              </Media.Body>
            </Media>
          );
        })}
      </div>
    </>
  );
};

export default JobList;
