import React from 'react';
import { Link } from 'gatsby';
import kebabCase from 'lodash.kebabcase';
import styled from 'styled-components';
import Badge from 'react-bootstrap/Badge';

const Container = styled.div`
  > :not(:last-child) {
    margin-right: 10px;
  }
`;

const JobTagList = ({ tags }) => {
  if (!tags?.length) {
    return null;
  }
  return (
    <Container>
      {tags.map(tag => {
        return (
          <Link to={`/jobs/${kebabCase(tag)}`} key={tag}>
            <Badge variant="dark" pill>
              {tag}
            </Badge>
          </Link>
        );
      })}
    </Container>
  );
};

export default JobTagList;
